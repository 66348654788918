import React, { useEffect, useState, useCallback } from "react";
import ApiService from "../../apiService";
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import {
  roleList,
  options,
  allStatus,
  adminStatusList,
  onlyAdminStatus,
  afterSubmitToAto,
  beforeSubmitToAtoFAdmin,
  onlyStaffStatus,
} from "../../utils/constant";
import apiService from "../../apiService";
import { isAdmin } from "../../utils/auth";
import Pagination from "../shared/Pagination";
import ClientViewModel from "../model/ClientView";
import "react-dropdown/style.css";
import { getLeadData, trimText } from "../../utils/function";

const LeadsTable = ({ title, showButton, userType }) => {
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [leadToDelete, setLeadToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [openSendToClient, setOpenSendToClient] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});
  const [campaignDetails, setCampaignDetails] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("select");
  const [selectedRole, setSelectedRole] = useState(roleList[0].value);
  const [selectedUser, setSelectedUser] = useState("");
  const [assigneeList, setAssigneeList] = useState([]);
  const [campList, setCampList] = useState([]);
  const [selectedCamp, setSelectedCamp] = useState("select");

  const isUserAdmin = isAdmin();
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const result = await ApiService.leads.getLeads({
          userType,
          selectedStatus: selectedStatus === "select" ? "" : selectedStatus,
          page,
          selectedUser,
          limit: rowsPerPage,
          selectedCamp: selectedCamp === "select" ? "" : selectedCamp,
        });
        if (result?.success) {
          const { data } = result;
          setLeads(data.leads || []);
          setTotalPages(data.totalPages || 1);
          setCurrentPage(data.currentPage || 1);
          setTotalData(data.totalLeads);
        }
      } catch (error) {
        console.error("Failed to fetch leads:", error);
        toast.error("Failed to fetch leads.");
      } finally {
        setLoading(false);
      }
    },
    [selectedStatus, selectedCamp, selectedUser, userType, rowsPerPage]
  );

  const fetchCampsDetails = async () => {
    try {
      const result = await apiService.campaigns.getWithDetails();
      const campaigns = result?.data?.campaigns || [];
      setCampList(
        campaigns?.map((ca) => ({ label: ca?.title, value: ca.campId }))
      );
      setCampaignDetails(campaigns);
    } catch (error) { }
  };

  useEffect(() => {
    if (userType !== "admin") {
      fetchCampsDetails();
    }
  }, []);

  const fetchAssigneeData = useCallback(async () => {
    const assignee = await apiService.auth.getUser("staff", "", -1, "verifier");
    const options = assignee?.data?.users?.map((ass) => ({
      label: ass.name,
      value: ass._id,
    }));
    setAssigneeList(options || []);
  }, []);

  useEffect(() => {
    fetchAssigneeData();
  }, [fetchAssigneeData]);

  const fetchUserList = useCallback(async () => {
    if (selectedRole && selectedRole !== "all") {
      const { data } = await apiService.auth.getUser(selectedRole);
      const userOption = data?.users.map((user) => ({
        label: user.name,
        value: user._id,
      }));
      setUserList(userOption);
    } else {
      setUserList([]);
      setSelectedUser("");
    }
  }, [selectedRole]);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList, selectedRole]);

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage, selectedStatus, selectedUser, rowsPerPage]);

  const handleEditLead = (lead, campaignId) => {
    navigate(`/crm/leads/edit/${lead.leadId}/?campaignId=${campaignId}`);
  };

  const openDeleteConfirmation = (leadId) => {
    setLeadToDelete(leadId);
    setIsDeleteConfirmationOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setLeadToDelete(null);
    document.body.style.overflow = "unset";
  };

  const handleDelete = async () => {
    if (!leadToDelete) return;
    setIsDeleting(true);
    try {
      await ApiService.leads.deleteLeads(leadToDelete);
      fetchData();
      toast.success("Lead deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete lead.");
    } finally {
      setIsDeleting(false);
      closeDeleteConfirmation();
    }
  };

  const handleDropdownChange = (option) => {
    setSelectedStatus(option.value);
  };

  const handleUserChange = (option) => {
    setSelectedUser(option.value);
  };

  const handleRoleChange = (option) => {
    setSelectedRole(option.value);
  };

  const onAssigneeChange = async (assigneeId, leadId) => {
    try {
      const updatedLead = await apiService.leads.updateLead(leadId, {
        verifier: assigneeId,
        status: "under_verification",
      });
      if (updatedLead.success) {
        toast.success(updatedLead.message);
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.leadId === leadId ? { ...lead, verifier: assigneeId } : lead
          )
        );
        fetchData();
      }
    } catch (error) {
      console.error("Error updating assignee:", error);
      toast.error("Failed to update assignee.");
    }
  };

  const isAssigneeDisabled = (status) => {
    return status !== "new";
  };

  const getAssigneeLabel = (id) => {
    let label = assigneeList.find((user) => user.value === id)?.label;
    return label || "";
  };

  const getStatusLabel = (status) => {
    let label = allStatus.find((s) => s.value === status)?.label;
    return label || "";
  };

  const getLeadStatus = (status) => {
    if (
      userType !== "admin" &&
      userType !== "vendor" &&
      onlyAdminStatus.includes(status)
    ) {
      return "Submitted To Attorney";
    }

    return allStatus.find((d) => d.value === status)?.label || status;
  };

  const onStatusChange = async (leadId, status) => {
    try {
      const data = await apiService.leads.updateLead(leadId, { status });
      if (data.success) {
        toast.success(data?.message || "");
        fetchData();
      }
    } catch (error) {
      console.error("Failed to update lead status:", error);
    }
  };

  const canDisableSendToClient = (lead) => {
    return lead.createdBy.userType === "staff" && lead.status !== "verified";
  };

  const hideSendButton = (status, userType) => {

    if ([...afterSubmitToAto, "submitted_to_attorney"].includes(status)) {
      return false;
    } else if (userType === "staff" && status === "verified") {
      return true;
    } else if (userType === "vendor" && (status !== "new" || status !== "verified")) {
      return true;
    }
    return false;
  };

  const getUserStatus = (status, role) => {
    // uncomment this if you want to remove options in status submitted_to_attorney
    // if(status === 'submitted_to_attorney') {
    //   return []
    // } else
    if (
      status !== "submitted_to_attorney" &&
      !afterSubmitToAto.includes(status)
    ) {
      if (role === 'vendor') {
        return []
      }
      return beforeSubmitToAtoFAdmin;
    } else {
      if (role === "staff") {
        return adminStatusList.filter(
          (option) =>
            afterSubmitToAto.includes(option.value) &&
            !["billable", "paid"].includes(option.value)
        );
      } else if (role === "vendor") {
        return adminStatusList.filter((option) =>
          afterSubmitToAto.includes(option.value)
        );
      }
    }
    return adminStatusList;
  };

  const getFilterStatus = () => {
    if (userType === "admin") {
      return allStatus;
    }
    return allStatus.filter((s) =>
      userType === "staff"
        ? !onlyAdminStatus.includes(s.value)
        : !onlyAdminStatus.includes(s.value) &&
        !onlyStaffStatus.includes(s.value)
    );
  };
  return (
    <>
      <div className="flex sm:justify-end justify-between gap-2">
        {!isUserAdmin && (
          <div className=" w-full flex items-center gap-2 flex-wrap justify-end">
            {campaignDetails?.map((camp) => (
              <button
                className="bg-blue-200 text-black font-bold py-2 px-4 rounded-full relative group"
                onClick={() => navigate(`/crm/leads/create/${camp.campId}`)}
              >
                {trimText(camp?.title, 15)}

                <span className="absolute top-full left-7 mt-2 w-max px-2 py-1 bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-50">
                  {camp?.title}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="border bg-white border-border-dark mt-4 rounded-[20px] overflow-hidden">
        <div className="flex items-center py-1 px-4 justify-between relative flex-wrap w-full">
          <h1 className="font-bold p-2 leading-8 text-end text-2xl w-1/2">
            {title}
          </h1>
          <div className="flex sm:justify-end justify-between gap-2 w-1/2">
            {!isUserAdmin && (
              <Dropdown
                options={[
                  { label: "Select Campaign", value: "select" },
                  { label: "All", value: "" },
                  ...campList,
                ]}
                onChange={(option) => setSelectedCamp(option.value)}
                value={selectedCamp}
                placeholder="All"
                className="font-medium shrink-0 min-w-dropdown"
              />
            )}
            {isUserAdmin && (
              <Dropdown
                options={roleList}
                onChange={handleRoleChange}
                value={selectedRole}
                placeholder="Select an Role"
                className="font-medium shrink-0"
              />
            )}
            {isUserAdmin && (
              <Dropdown
                options={userList}
                onChange={handleUserChange}
                value={selectedUser}
                placeholder="Select User"
                className="font-medium shrink-0"
                disabled={selectedRole === "all"}
              />
            )}
            <Dropdown
              options={[
                { label: "Select Status", value: "select" },
                { label: "All", value: "" },
                ...getFilterStatus(),
              ]}
              onChange={handleDropdownChange}
              value={selectedStatus}
              placeholder="All"
              className="font-medium  shrink-0 min-w-dropdown"
            />
          </div>
          {/* {showButton && (
            <button
              className="absolute right-4 top-3"
              onClick={() => navigate("/crm/leads/create")}
            >
              <img src={"/assets/svg/plusbutton.svg"} alt="Add" />
            </button>
          )} */}
        </div>

        <div className="w-full border-t border-border-dark min-h-[300px]">
          {loading && <SkeletonLoader />}
          {!loading &&
            (leads.length === 0 ? (
              <p className="text-center py-4">No leads found</p>
            ) : (
              <table className="w-full border-collapse scrolldown">
                <thead>
                  <tr className="bg-[#F6F6F6] h-[41.9px]">
                    <th className="text-base font-bold px-4 border-r border-border-dark w-[5%]">
                      Lead Id
                    </th>
                    <th className="text-base font-bold px-4 border-r border-border-dark w-[15%]">
                      Username
                    </th>
                    <th className="text-base font-bold px-4 border-r border-border-dark w-[10%]">
                      Email
                    </th>
                    <th className="text-base font-bold px-4 border-r border-border-dark w-[10%]">
                      Contact Number
                    </th>
                    {isUserAdmin && (
                      <th className="text-base font-bold px-4 border-r border-border-dark w-[15%]">
                        Assignee
                      </th>
                    )}
                    <th className="text-base font-bold px-4 border-r border-border-dark w-[15%]">
                      Status
                    </th>
                    {isUserAdmin && (
                      <th className="text-base font-bold px-4 border-r border-border-dark w-[12%]">
                        Send to client
                      </th>
                    )}
                    {isUserAdmin && (
                      <th className="text-base font-bold px-4 w-[5%]">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white w-full">
                  {leads?.map((lead, index) => (
                    <tr
                      key={lead.leadId}
                      className="border-b border-border-dark"
                      style={{ cursor: isUserAdmin ? "pointer" : "default" }}
                      onClick={() => {
                        if (isUserAdmin) {
                          navigate(`/crm/leads/view/${lead.leadId}`);
                        }
                      }}
                    >
                      <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                        {lead?.leadId}
                      </td>
                      <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                        {getLeadData(lead?.responses, 'name') || lead?.createdBy.name}
                      </td>
                      <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                        {getLeadData(lead?.responses, 'email') || '-'}
                      </td>
                      <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                        {getLeadData(lead?.responses, "number") || '-'}
                      </td>
                      {isUserAdmin && (
                        <td
                          className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark"
                          style={{ textTransform: "capitalize" }}
                        >
                          {lead.createdBy.userType !== "staff" ? (
                            "-"
                          ) : (
                            <div onClick={(event) => event.stopPropagation()}>
                              <Dropdown
                                options={[
                                  { label: "Assignee", value: "" },
                                  ...assigneeList,
                                ]}
                                onChange={(e) => {
                                  onAssigneeChange(e.value, lead.leadId);
                                }}
                                value={getAssigneeLabel(lead?.verifier)}
                                placeholder="Select an Assignee"
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                className="font-medium"
                                disabled={
                                  isAssigneeDisabled(lead.status) ||
                                  lead.verifier
                                }
                              />
                            </div>
                          )}
                        </td>
                      )}
                      {isUserAdmin && (
                        <td
                          className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div onClick={(event) => event.stopPropagation()}>
                            <Dropdown
                              options={getUserStatus(
                                lead.status,
                                lead.createdBy.userType
                              )}
                              onChange={(e) => {
                                onStatusChange(lead.leadId, e.value);
                              }}
                              value={getStatusLabel(lead?.status)}
                              placeholder="Status"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              className="font-medium"
                            />
                          </div>
                        </td>
                      )}
                      {!isUserAdmin && (
                        <td
                          className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark"
                          style={{ textTransform: "capitalize" }}
                        >
                          {getLeadStatus(lead.status)}
                        </td>
                      )}
                      {isUserAdmin && (
                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                          {hideSendButton(
                            lead.status,
                            lead.createdBy.userType
                          ) ? (
                            <button
                              disabled={canDisableSendToClient(lead)}
                              onClick={(event) => {
                                event.stopPropagation();
                                setSelectedLead(lead);
                                setOpenSendToClient(true);
                              }}
                              className="px-4 py-2 bg-black text-white text-sm border border-border-color font-semibold rounded-lg disabled:opacity-50"
                            >
                              Send
                            </button>
                          ) : (
                            lead?.clientId?.name || "-"
                          )}
                        </td>
                      )}
                      {isUserAdmin && (
                        <td
                          className="text-base font-bold px-2 py-3 flex justify-center items-center gap-1"
                          onClick={(event) => event.stopPropagation()}>
                          {isUserAdmin && (
                            <>
                              <button
                                className="text-sm font-bold text-black"
                                onClick={() =>
                                  handleEditLead(lead, lead?.campaign?._id)
                                }
                              >
                                Edit
                              </button>
                            </>
                          )}
                          {/* <button
                          className="text-sm font-bold text-[#C30000]"
                          onClick={() => openDeleteConfirmation(lead.id)}
                        >
                          Delete
                        </button> */}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalData={totalData}
          onPageChange={setCurrentPage}
          loading={loading}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
            setCurrentPage(1);
          }}
        />

        <ClientViewModel
          isOpen={openSendToClient}
          onClose={() => {
            setOpenSendToClient(false);
          }}
          fetchData={fetchData}
          lead={selectedLead}
        />

        {isDeleteConfirmationOpen && (
          <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-4">
              <p className="text-lg font-semibold py-10">
                Are you sure you want to delete this lead?
              </p>
              <div className="flex justify-end mt-4 font-semibold">
                <button
                  className={`bg-[#AC1218] text-white py-1 ${isDeleting ? " px-6" : "px-2"
                    } text-sm font-semibold rounded-md`}
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  {isDeleting ? <div className="loader"></div> : "Delete"}
                </button>
                <button
                  className="ml-4 border border-border-color py-1 px-2 text-sm rounded-md"
                  onClick={closeDeleteConfirmation}
                  disabled={isDeleting}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LeadsTable;
