import React from "react";
import TableComponent from "../../components/table/TableComponent";
import Counter from "../../components/counter/Counter";
const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "pending", label: "Pending" },
];

const DashBoard = () => {
  return (
    // <div className="">
    //   <div className="">
    //     <div className=" w-full">

    //       {/* <div className=" mt-7">
    //         <TableComponent title={"Employees"} statusOptions={statusOptions} status={true} />
    //       </div> */}
    //     </div>
    //     {/* <div className="flex-[3]">
    //       <Counter/>

    //     </div> */}
    //   </div>
    // </div>
    <div className="h-[90%] w-full relative rounded-[20px] overflow-hidden">
      <img
        src="/assets/image/dashboard/dashboard1.png"
        alt=""
        className=" w-full  h-full object-cover brightness-50 "
      />

      <h5 className=" absolute left-20 top-24 leading-[60px] text-white text-5xl font-bold">
        Dear User, <br />
        Welcome to Lead Briefcase
      </h5>
    </div>
  );
};

export default DashBoard;
