import React from 'react'
import VendorTable from '../../components/table/VendorTable'


const Vendors = () => {
  return (
    <VendorTable title={"Vendors"} showButton={true} />
  )
}

export default Vendors
