import React from "react";
import QuestionTable from "../../components/table/Question";

const Question = () => {
  return (
      <QuestionTable
        title={"Questions"}
        showButton={true}
      />
  );
};

export default Question;
