import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../apiService";
import { useParams } from "react-router-dom";
import Skeleton from "../skeletonloader/Request";
import { getUserRoleFromToken } from "../../utils/auth";
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg';
import { ReactComponent as AudioIcon } from '../../assets/icons/video.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { formatDate, handleDownload } from "../../utils/function";
import { allStatus } from "../../utils/constant";

const errorCode = [400, 401, 500, 501];

const AdminLeadView = () => {
  const { id: leadId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const userType = getUserRoleFromToken();
  const [openIndex, setOpenIndex] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await apiService.leads.getById(leadId, userType);
      if (result?.success) {
        setData(result.data);
        setOpenIndex(result.data.responses.map((_, index) => index));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [leadId, userType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleAccordion = (index) => {
    setOpenIndex((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="mt-4 overflow-hidden">
      {loading ? (
        <Skeleton count={4} />
      ) : (
        <>
          <div className="flex flex-col gap-2 py-5 bg-white p-3 border border-border-dark rounded-md">
            <div className="flex justify-between gap-2 w-full">
              <h1 className="text-lg">
                Created By -{" "}
                <b>
                  {data?.createdBy?.name} {`(${data?.createdBy?.userType})`}
                </b>
              </h1>
              <h1>
                Status: <b style={{ textTransform: "capitalize" }}>{allStatus.find((s)=> s?.value === data?.status)?.label}</b>
              </h1>
            </div>
          </div>
          <div className="mt-10 bg-white py-5 rounded-md border border-border-dark">
            <p className="text-center text-2xl pb-3 border-b border-border-color">
              <b>Answers</b>
            </p>

            <div className="flex gap-2 w-full mt-5 justify-center items-center">
              {data?.media?.map((file, index) => (
                <div className="flex items-center w-full md:w-[45%] justify-between pr-3 bg-[#F9F9F9] p-3" key={file._id}>
                  <div className="flex gap-2 items-center">
                    <b>
                      {file.type === 'doc' ? 'Submitted document' : 'Submitted video/audio'}
                    </b>
                    {file.type === 'doc' ? <DocumentIcon /> : file.type === 'recording' ? <AudioIcon /> : null}
                  </div>
                  <DownloadIcon
                    className="cursor-pointer"
                    onClick={() => handleDownload(file.url, file.url.split("/").pop())}
                  />
                </div>
              ))}
            </div>

            <div className="rounded-md mt-2 overflow-x-auto flex flex-wrap gap-1 items-center max-h-[500px] overflow-y-auto justify-center">
              {data?.responses?.map((resp, index) => (
                <div
                  key={index}
                  className="bg-[#F9F9F9] border-b border-border-color w-full md:w-[45%]"
                >
                  <div
                    onClick={() => toggleAccordion(index)}
                    className="flex items-center justify-between p-3 cursor-pointer"
                  >
                    <h1>
                      <b>
                        {index + 1}. {resp?.questionTitle}
                      </b>
                    </h1>
                    <b
                      className={`text-lg transition-transform duration-300 ease-in-out ${openIndex.includes(index) ? "rotate-180" : "rotate-0"
                        }`}
                    >
                      {openIndex.includes(index) ? "-" : "+"}
                    </b>
                  </div>
                  <div
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${openIndex.includes(index)
                      ? "max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                      }`}
                  >
                    <div className="px-3 pb-3">
                      <p>{resp?.response}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {data?.apiLogs?.length > 0 && (
            <div className="mt-10 bg-white border border-border-dark rounded-md overflow-hidden">
              <p className="text-center text-2xl mt-4 pb-3 border-b border-border-color">
                <b>API Logs</b>
              </p>
              <div className="w-full overflow-x-auto max-h-[300px] overflow-y-auto">
                <table className="w-full border-collapse scrolldown">
                  <thead className="bg-[#F6F6F6] sticky top-0">
                    <tr className="h-[41.9px] w-full">
                      <th className="text-base font-bold px-4 border-r border-border-dark">
                        S.No.
                      </th>
                      <th className="text-base font-bold px-4 border-r border-border-dark">
                        Created At
                      </th>
                      <th className="text-base font-bold px-4 border-r border-border-dark">
                        Response Status Code
                      </th>
                      <th className="text-base font-bold px-4">Error</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white w-full">
                    {data.apiLogs?.map((log, index) => (
                      <tr key={log.id} className="border-b border-border-dark">
                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                          {index + 1}
                        </td>
                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                          {formatDate(log.createdAt)}
                        </td>
                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                          {log.responseStatusCode}
                        </td>
                        {errorCode.includes(log.responseStatusCode) ? (
                          <td className="text-sm text-center font-bold px-2 py-3">
                            {log?.response?.data?.message || log?.response?.message}
                          </td>
                        ) : (
                          <p className="text-center">-</p>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminLeadView;
