import React from 'react'
import LeadsTable from '../../components/table/Leads'
import { getUserRoleFromToken } from "../../utils/auth";

const Leads = () => {
  const userType = getUserRoleFromToken()
  return (
    <LeadsTable title={"Leads"} userType={userType} showButton={userType !== 'admin'} />
  )
}

export default Leads
