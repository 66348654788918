import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getUserMenuByRole, getUserRoleFromToken, logOutUser } from "../../utils/auth";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const userRole = getUserRoleFromToken();

  const handleToggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleToggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      sidebarOpen &&
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target)
    ) {
      if (!event.target.closest(".fa-xmark")) {
        setSidebarOpen(false);
      }
    }
    if (
      dropdownOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };
  
  const handleLinkClick = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    };
  }, [sidebarOpen]);

  const isHomePage = location.pathname === "/";

  const getUserMenu = useCallback(() => {
    return getUserMenuByRole(userRole)
  }, [userRole]);

  if (isHomePage) {
    return null;
  }

  return (
    <header className="sticky top-0 z-50 flex items-center justify-between px-6 py-2 bg-white border-b border-border-color">
      <NavLink to="" className=" h-14 w-16  rounded-full max-lg:hidden">
        <img src="/assets/svg/logo/Leadbriefcase_logo.png" className=" h-full w-full object-cover" alt="" />
      </NavLink>
      <button className="lg:hidden" onClick={handleToggleSidebar}>
        {sidebarOpen ? (
          <i className="fa-solid fa-xmark"></i>
        ) : (
          <i className="fa-solid fa-bars"></i>
        )}
      </button>
      <div className="flex items-center gap-11">
        <img
          src="/assets/svg/header/bell.svg"
          alt="Notifications"
          className="w-6 h-6 cursor-pointer"
        />
        <div className="relative" ref={dropdownRef}>
          <button onClick={handleToggleDropdown} className="flex items-center px-4 py-2 rounded-[10px] border border-border-color gap-2">
            <img
              src="/assets/svg/avtar/avtar.png"
              className="rounded-full h-9 w-9"
              alt="Profile"
            />
            <img
              src="/assets/svg/header/donchevron.svg"
              alt="Dropdown Arrow"
              className="w-4 h-4 ml-2"
            />
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 z-50 w-48 mt-2 bg-white border rounded-md shadow-lg border-border-dark">
              <ul>
                <li>
                  <NavLink
                    to=""
                    onClick={handleLinkClick}
                    className="block px-4 py-2 hover:bg-gray-200"
                  >
                    Profile
                  </NavLink>
                </li>
                <li
                  onClick={logOutUser}
                  className="block px-4 py-2 hover:bg-gray-200 cursor-pointer"
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {sidebarOpen && (
        <div
          ref={sidebarRef}
          className="bg-white min-h-screen absolute top-[70px] w-64 border-r left-0 border-border-color z-50 h-full px-5"
        >
          <ul>
            {getUserMenu().map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.href}
                  onClick={handleLinkClick}
                  className={({ isActive }) =>
                    `navlist flex items-center py-2 ${isActive ? "!text-black font-bold" : ""
                    }`
                  }
                >
                  <img
                    src={item.icon}
                    alt={item.name}
                    className="w-5 h-5 mr-2"
                  />
                  <span>{item.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
