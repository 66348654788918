import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import ApiService from "../../apiService";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserRoleFromToken } from "../../utils/auth";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LeadForm = ({ isEditMode }) => {
  const { id: leadId, campId } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const campaignId = query.get("campaignId");

  const [loading, setLoading] = useState(false);
  const [camp, setCamp] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [uploadedRecording, setUploadedRecording] = useState(null);
  const navigate = useNavigate();

  const userType = getUserRoleFromToken();

  useEffect(() => {
    if (campaignId && camp.length > 0) {
      const matchedCampaign = camp.find((d) => d.value === campaignId);
      if (matchedCampaign) {
        // setSelectedCamp(matchedCampaign.value);
        fetchQuestions(matchedCampaign.value);
      }
    }
  }, [camp, campaignId]);

  useEffect(() => {
    if (isEditMode && leadId) {
      fetchLeadData(leadId);
    }
  }, [leadId, isEditMode]);

  useEffect(() => {
    fetchCamps();
  }, []);

  useEffect(() => {
    if (campId || campaignId) {
      fetchQuestions(campId || campaignId);
    }
  }, [camp, campId, campaignId]);

  const fetchQuestions = async (campId) => {
    setLoading(true);
    try {
      const result = await ApiService.question.getQuestionsByCampId(campId);
      if (result?.success) {
        setQuestions(result.data || []);
      }
    } catch (error) {
      toast.error("Failed to fetch questions.");
    } finally {
      setLoading(false);
    }
  };

  const fetchLeadData = async (id) => {
    setLoading(true);
    try {
      const result = await ApiService.leads.getLeadById(id, "vendor");
      if (result?.success) {
        const { data } = result;
        setResponses(
          data.responses.reduce((acc, response) => {
            acc[response.questionId] = response.response;
            return acc;
          }, {})
        );
      }
    } catch (error) {
      toast.error("Failed to fetch lead data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCamps = async () => {
    try {
      const data = await ApiService.campaigns.getById(campId || campaignId);
      if (data?.success) {
        setCamp(
          data.data.map((camp) => ({ label: camp.title, value: camp._id }))
        );
      }
    } catch (error) {
      toast.error("Failed to fetch campaigns.");
    }
  };

  const handleInputChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (type === "doc") {
      setUploadedDoc(file);
    } else if (type === "recording") {
      setUploadedRecording(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if files are uploaded for vendors
    if (!isEditMode && userType === "vendor" && !uploadedRecording) {
      toast.error("Both Document and Recording are required for vendors.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("campaignId", campId || campaignId);

    Object.keys(responses).forEach((questionId) => {
      formData.append(
        "responses[]",
        JSON.stringify({
          questionId,
          response: responses[questionId],
        })
      );
    });

    if (uploadedDoc) formData.append("media", uploadedDoc); // Single 'media' field for both files
    if (uploadedRecording) formData.append("media", uploadedRecording);

    try {
      let response;
      if (isEditMode) {
        response = await ApiService.leads.updateLead(leadId, formData);
      } else {
        response = await ApiService.leads.createLead(formData);
      }

      if (response?.success) {
        toast.success(
          isEditMode
            ? "Lead updated successfully!"
            : "Lead created successfully!"
        );
        navigate("/crm/leads");
      }
    } catch (error) {
      toast.error("Failed to save lead.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lead-form-container bg-white pb-10 border-border-color border rounded-md overflow-hidden">
      <h1 className="text-xl font-bold text-center py-3 border-border-color border-b">
        {isEditMode ? "Edit Lead" : "Create New Lead"}
      </h1>
      {/* <div className='p-4 flex items-center mt-5'>
                <label className="font-semibold text-base leading-5 flex-[4]">Select Campaign</label>
                <div className='flex-[8]'>
                    <Dropdown
                        options={[{ label: "Select Campaign", value: "" }, ...camp]}
                        onChange={(e) => setSelectedCamp(e.value)}
                        value={selectedCamp}
                        placeholder="Select a camp"
                        className="font-medium z-50"
                        disabled={!!campaignId}
                    />
                </div>
            </div> */}
      <form onSubmit={handleSubmit} className="p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          {questions?.map((question) => (
            <div key={question.questionId} className="form-group mt-5">
              <label className="text-lg font-medium">
                {question.title}{" "}
                {question.isRequired && <span className="text-red-500">*</span>}
              </label>
              {question.type === "text" && (
                <input
                  type="text"
                  value={responses[question.questionId] || ""}
                  onChange={(e) =>
                    handleInputChange(question.questionId, e.target.value)
                  }
                  required={question.isRequired}
                  className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
                />
              )}
              {question.type === "radio" && (
                <div className="control mt-1 flex gap-3 items-center">
                  <label className="radio flex gap-1 items-center">
                    <input
                      type="radio"
                      name={`question_${question.questionId}`}
                      checked={responses[question.questionId]?.toLowerCase() === "yes"}
                      onChange={() =>
                        handleInputChange(question.questionId, "Yes")
                      }
                    />
                    Yes
                  </label>
                  <label className="radio flex gap-1 items-center">
                    <input
                      type="radio"
                      name={`question_${question.questionId}`}
                      checked={responses[question.questionId]?.toLowerCase() === "no"}
                      onChange={() =>
                        handleInputChange(question.questionId, "No")
                      }
                    />
                    No
                  </label>
                </div>
              )}
              {question.type === "number" && (
                <input
                  type="number"
                  value={responses[question.questionId] || ""}
                  onChange={(e) =>
                    handleInputChange(question.questionId, e.target.value)
                  }
                  required={question.isRequired}
                  className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
                />
              )}
              {question.type === "date" && (
                <DatePicker
                  selected={
                    responses[question.questionId]
                      ? new Date(responses[question.questionId].replace(/-/g, '/')) // Convert to Date only if valid
                      : null
                  }
                  onChange={(date) =>
                    handleInputChange(
                      question.questionId,
                      date ? format(date, "MM-dd-yyyy") : ""
                    )
                  }
                  dateFormat="MM-dd-yyyy"
                  placeholderText="mm-dd-yyyy"
                  required={question.isRequired}
                  className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
                  wrapperClassName="w-full"
                />
              )}
              {question.type === "email" && (
                <input
                  type="email"
                  value={responses[question.questionId] || ""}
                  onChange={(e) =>
                    handleInputChange(question.questionId, e.target.value)
                  }
                  required={question.isRequired}
                  className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
                />
              )}
            </div>
          ))}
        </div>

        {!loading && (
          <div className="relative border border-[#C4C4C4] rounded-[5px] px-3 py-4 mt-[34px]">
            <span className="absolute top-2 left-1 font-semibold text-base leading-5 transform translate-y-[-100%] bg-white text-black">
              Upload Documentation
            </span>
            {questions.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                <div className="form-group mt-5 flex-col flex">
                  <label className="text-lg font-medium">Upload Document</label>
                  {/* <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "doc")}
                    className="mt-2 border border-[#C4C4C4] p-2 rounded-md"
                  /> */}

                  {/* custom file start  */}

                  <div className="flex items-center justify-center w-full mt-2 ">
                    <label
                      className="flex flex-col items-center justify-center w-full  border rounded-md cursor-pointer bg-white overflow-hidden   border-border-dark"
                    >
                      <div className="flex flex-col items-center justify-center bg-[#F9F9F9] w-full pt-4 pb-4">
                        <svg
                          className="w-8 h-8 mb-2 text-black dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold text-black">
                            {uploadedDoc?.name ? uploadedDoc.name : 'Click to upload Document'}
                          </span>
                        </p>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        onChange={(e) => handleFileChange(e, "doc")}
                      />
                    </label>
                  </div>

                  {/* custom file end */}
                </div>

                <div className="form-group mt-5 flex-col flex">
                  <label className="text-lg font-medium">
                    Upload Recording{" "}
                    {userType === "vendor" && (
                      <span className="text-red-600">*</span>
                    )}
                  </label>
                  {/* <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "recording")}
                    accept="audio/*, video/*"
                    className="mt-2 border border-[#C4C4C4] p-2 rounded-md"
                  /> */}

                  {/* custom file start  */}

                  <div className="flex items-center justify-center w-full mt-2 ">
                    <label
                      className="flex flex-col items-center justify-center w-full  border rounded-md cursor-pointer bg-white overflow-hidden   border-border-dark"
                    >
                      <div className="flex flex-col items-center justify-center bg-[#F9F9F9] w-full pt-4 pb-4">
                        <svg
                          className="w-8 h-8 mb-2 text-black dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold text-black">
                            {uploadedRecording?.name ? uploadedRecording?.name : 'Click to upload Recording'}
                          </span>
                        </p>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        accept="audio/*, video/*"
                        onChange={(e) => handleFileChange(e, "recording")}
                      />
                    </label>
                  </div>

                  {/* custom file end */}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex mt-6 justify-center">
          <button
            type="submit"
            className={`py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold ${loading || (userType === "vendor" && !uploadedRecording)
              ? "opacity-50 cursor-not-allowed"
              : ""
              }}`}
            disabled={
              loading ||
              (!isEditMode && userType === "vendor" && !uploadedRecording)
            }
          >
            {loading ? (
              <div className="loader" />
            ) : isEditMode ? (
              "Update Lead"
            ) : (
              "Create Lead"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LeadForm;
