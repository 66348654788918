import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class AuthService {
    async login(body) {
        try {
            const response = await axios.post('/auth/login', body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;
        }
    }
    async register(userData) {
        try {
            const response = await axios.post('/auth/register', userData);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }
    async getUser(userType, search = '', page = -1, role = '', limit = 10) {
        try {
            const response = await axios.get(`/auth/users?userType=${userType}&page=${page}&search=${search}&role=${role}&limit=${limit}`,);
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async updateUser(id, payload) {
        try {
            const response = await axios.put('/auth/user/' + id, payload);
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/auth/user/' + id);
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    }
    async verifyToken() {
        try {
            const response = await axios.get('/auth/verifyToken');
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default new AuthService();
