import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class ClientService {
    async sendToClient(body) {
        try {
            const response = await axios.post('/client/sendToClient', body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw error;
        }
    }
}

export default new ClientService();
