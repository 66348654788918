import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class CampaignsService {
    async get(search = '', page = 0 , limit = 10) {
        try {
            const response = await axios.get(`/campaign?search=${search}&page=${page}&limit=${limit}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false
        }
    }

    async getWithDetails() {
        try {
            const response = await axios.get(`/campaign/getWithDetails`);
            return response.data;
        } catch (error) {
            throw Error(error);

        }
    }

    async getById(campId) {
        try {
            const response = await axios.get(`/campaign/getById?campId=${campId}`);
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }
    
    async create(body) {
        try {
            const response = await axios.post('/campaign', body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async update(id, body) {
        try {
            const response = await axios.put(`/campaign/${id}`, body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);

        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`/campaign/${id}`);
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }
}

export default new CampaignsService();
