import React, { useCallback, useEffect, useState } from "react";
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import AddCampaignModel from "../model/AddCampaign";
import apiService from '../../apiService';
import { toast } from 'react-toastify';
import Pagination from '../shared/Pagination';

const CampaignsTable = ({ title = "", showButton }) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCamp, setSelectedCamp] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [campToDelete, setCampToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const fetchData = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const result = await apiService.campaigns.get(debouncedSearchQuery, page, rowsPerPage);
      setData(result?.data?.campaigns || []);
      setTotalPages(result.data.totalPages || 0);
      setCurrentPage(result.data.currentPage || 0);
      setTotalData(result.data.totalCampaigns || 0);
    } catch (error) {
      console.error('Failed to fetch Campaign:', error);
    } finally {
      setLoading(false);
    }
  }, [debouncedSearchQuery, rowsPerPage]);

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage, rowsPerPage]);

  const handleDelete = async () => {
    if (!campToDelete) return;

    setIsDeleting(true); 
    try {
      const data = await apiService.campaigns.delete(campToDelete);
      if (data.success) {
        toast.success('Campaign deleted successfully!');
        fetchData();
      }
    } catch (error) {
      console.error('Error deleting campaign:', error);
      toast.error('Failed to delete campaign.');
    } finally {
      setIsDeleting(false); 
      closeDeleteConfirmation();
    }
  };

  const openDeleteConfirmation = (campId) => {
    setCampToDelete(campId);
    setIsDeleteConfirmationOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setCampToDelete(null);
    document.body.style.overflow = 'unset';
  };

  const handleEdit = (camp) => {
    setSelectedCamp(camp);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="flex flex-col space-y-3">
        <div className="flex justify-end">
          <div className="w-full md:w-4/12">
            <div className="overflow-hidden border border-gray-300 shadow-d rounded-[20px]">
              <div className="relative flex items-end justify-end py-2 bg-white">
                <div className="relative flex items-center w-full">
                  <input
                    type="text"
                    value={searchQuery}
                    className="w-full py-1 pl-12 pr-4 text-sm font-medium text-gray-700 rounded-[20px] focus:outline-none"
                    placeholder="Search campaigns"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="absolute inset-y-0 flex items-center pointer-events-none left-5">
                    <i className="text-lg text-gray-500 fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border mt-4 bg-white border-border-dark rounded-[20px] overflow-hidden">
        <div className="flex items-center py-1 px-4 justify-center relative">
          <h1 className="font-bold p-2 text-center leading-8 text-2xl">
            {title}
          </h1>
          {showButton && (
            <button
              className="absolute right-4 top-3"
              onClick={() => {
                setSelectedCamp(null);
                setIsModalOpen(true);
              }}
            >
              <img src={"/assets/svg/plusbutton.svg"} alt="Add" />
            </button>
          )}
        </div>
        <div className="w-full overflow-x-auto border-t border-border-dark min-h-[300px]">
          {loading && (
            <SkeletonLoader />
          )}
          {!loading && (data.length === 0 ? (
            <p className="text-center py-4">No Campaign available</p>
          ) : (
            <table className="w-full border-collapse scrolldown">
              <thead>
                <tr className="bg-[#F6F6F6] h-[41.9px]">
                  <th className="text-base font-bold px-4 border-r border-border-dark w-[8%]">S no.</th>
                  <th className="text-base font-bold px-4 border-r border-border-dark w-[22%]">Title</th>
                  <th className="text-base font-bold px-4 w-[15%]">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white w-full">
                {data?.map((camp, index) => (
                  <tr key={camp._id} className="border-b border-border-dark">
                    <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                      {(currentPage - 1) * rowsPerPage + index + 1}
                    </td>
                    <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                      {camp?.title}
                    </td>
                    <td className="text-base font-bold px-2 py-3 flex justify-center items-center gap-1">
                      <button
                        className="text-sm font-bold text-black"
                        onClick={(event) =>{
                          event.stopPropagation()
                          handleEdit(camp)}}
                      >
                        Edit
                      </button>
                      {/* |
                      <button
                        className="text-sm font-bold text-[#C30000]"
                        onClick={(event) => {
                          event.stopPropagation()
                          openDeleteConfirmation(camp._id)}}
                      >
                       Delete
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          onPageChange={setCurrentPage}
          loading={loading}
          totalData={totalData}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
            setCurrentPage(1);
          }}
        />

        <AddCampaignModel
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          fetchData={fetchData}
          selectedCamp={selectedCamp}
        />

        {isDeleteConfirmationOpen && (
          <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-4">
              <p className="text-lg font-semibold py-10">Are you sure you want to delete this campaign?</p>
              <div className="flex justify-end mt-4 font-semibold">
                <button 
                  className={`bg-[#AC1218] text-white py-1 px-2 font-semibold text-sm rounded-md ${isDeleting ? 'px-6 opacity-50 cursor-not-allowed' : ''}`} 
                  onClick={handleDelete} 
                  disabled={isDeleting}
                >
                  {isDeleting ? <div className="loader" /> : "Delete"}
                </button>
                <button 
                  className="ml-4 border border-border-color py-1 px-2 text-sm rounded-md" 
                  onClick={closeDeleteConfirmation} 
                  disabled={isDeleting}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CampaignsTable;
