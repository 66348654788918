import React from 'react'

const ClientView = () => {
  return (
    <div className="mt-6 overflow-x-auto">
    <table className="w-full border-collapse lead-table">
      <thead>
        <tr className="bg-gray-200">
          <th className="border p-2 text-left font-semibold">Field Name</th>
          <th className="border p-2 text-left font-semibold">Response</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 6 }).map((_, index) => (
          <tr key={index} className="table-row animate-pulse border border-border-color mt-1">
            <td className=" p-2 bg-gray-200 border-r-2 border-white border-b-2 h-6"></td>
            <td className="border-r-2 border-white p-2 bg-gray-200 border-b-2 h-6"></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default ClientView


  