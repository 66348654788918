export const sidebarData = [
  {
    name: "Dashboard",
    icon: "/assets/svg/sidebar/home.svg",
    href: "dashboard"
  },
  {
    name: "Employees",
    icon: "/assets/svg/sidebar/employee.svg",
    href: "employees"

  },
  {
    name: "Leads",
    icon: "/assets/svg/sidebar/leads.svg",
    href: "leads"

  },
  {
    name: "Clients",
    icon: "/assets/svg/sidebar/client.svg",
    href: "clients"

  }, {
    name: "Vendors",
    icon: "/assets/svg/sidebar/vendor.svg",
    href: "vendors"
  },
];


export const sidebarDataForHeader = [
  {
    name: "Dashboard",
    icon: "/assets/svg/sidebar/home.svg",
    href: "/crm/dashboard",
    role: ['admin', "vendor", 'staff', 'client']
  },
  {
    name: "Staff",
    icon: "/assets/svg/sidebar/employee.svg",
    href: "/crm/staff",
    role: ['']
  },
  {
    name: "Leads",
    icon: "/assets/svg/sidebar/leads.svg",
    href: "/crm/leads",
    role: ['admin', "vendor", 'staff']
  },
  {
    name: "Clients",
    icon: "/assets/svg/sidebar/client.svg",
    href: "/crm/clients",
    role: ['admin']
  }, 
  {
    name: "Vendors",
    icon: "/assets/svg/sidebar/vendor.svg",
    href: "/crm/vendors",
    role: ['admin']
  },
  {
    name: "Questions",
    icon: "/assets/svg/sidebar/question-mark-svgrepo-com.svg",
    href: "/crm/question",
    role: ['admin']
  },
  {
    name: "Campaigns",
    icon: "/assets/svg/sidebar/questions.svg",
    href: "/crm/campaigns",
    role: ['admin']
  },
  {
    name: "Request",
    icon: "/assets/svg/sidebar/product-request-svgrepo-com.svg",
    href: "/crm/request",
    role: ['staff']
  },
];


