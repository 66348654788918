import React from "react";
import CampaignsTable from "../../components/table/Campaigns";

const Campaigns = () => {
  return (
      <CampaignsTable
        title={"Campaigns"}
        showButton={true}
      />
  );
};

export default Campaigns