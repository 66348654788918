import React from 'react';

const Request = ({ count = 4 }) => {
  return (
    <div className='flex flex-col gap-3 p-3'>
      {[...Array(count)].map((_, index) => (
        <div key={index} className='animate-pulse flex items-center justify-between mt-1 border-b pb-3 border-border-color'>
          <div className='flex-1 h-6 bg-gray-200 rounded-md'></div>
        </div>


      ))}
      <div className='flex justify-center mt-4'>
        <div className='animate-pulse h-10 w-32 bg-gray-200 rounded-md'></div>
      </div>
    </div>
  );
};

export default Request;
