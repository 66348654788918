import React from 'react'

const ApiLeadsForm = () => {
    return (
        <div className="mt-4 border bg-white border-border-dark rounded-[20px] overflow-hidden">
            <table className="w-full border-collapse scrolldown">
                <thead>
                    <tr className="bg-[#F6F6F6] h-[41.9px]">
                        <th className="text-base font-bold px-4 border-r border-border-dark w-[50%]">S.No.</th>
                        <th className="text-base font-bold px-4 border-r border-border-dark w-[50%]">S.No.</th>
                    </tr>
                </thead>
                <tbody className="bg-white w-full">
                    <tr className="border-b border-border-dark">
                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                            21
                        </td>
                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                            21
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ApiLeadsForm