import React from 'react'
import StaffTable from '../../components/table/Staff'


const Staff = () => {
  return (
    <StaffTable title={"Staff"} showButton={true} />
  )
}

export default Staff