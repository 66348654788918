import React, { useState, useRef, useEffect } from "react";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import useOutsideClick from "../../hooks/useOutSideClick";

const AddCampaignModel = ({ fetchData, isOpen, onClose, selectedCamp }) => {
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, () => setDropdownOpen(false));


  useEffect(() => {
    if (selectedCamp) {
      if (questions?.length > 0) {
        setSelectedQuestions(selectedCamp?.questions?.map((d) => {
          const option = questions?.find((q) => q._id === d?.questionId);
          return {...option, isRequired: d.isRequired};
        }));
      }
      setTitle(selectedCamp?.title);
    } else {
      // setSelectedQuestions([]);
      setTitle("");
    }
  }, [questions, selectedCamp]);

  useEffect(() => {
    fetchQuestions();
    if (!selectedCamp) {
      clearForm()
    }
  }, [selectedCamp]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const clearForm = () => {
    setTitle('')
    setSelectedQuestions([])
  }

  const fetchQuestions = async () => {
    try {
      const result = await apiService.question.getQuestions("", 0, -1);
      const fixedQuestion = result.data?.questions?.filter((d)=> d?.isFixed).map((d)=> ({...d, isRequired : true}))
      setSelectedQuestions(fixedQuestion)
      setQuestions(result.data.questions);
    } catch (error) {
      toast.error("Failed to fetch question.");
    }
  };

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const handleCheckboxChange = (option) => {
    setSelectedQuestions((prevSelected) => {
      const isSelected = prevSelected.some((q) => q._id === option._id);
      if (isSelected) {
        return prevSelected.filter((q) => q._id !== option._id);
      }
      return [...prevSelected, { ...option, isRequired: false }];
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const questions = selectedQuestions.map((q) => ({
      questionId: q._id,
      isRequired: q.isRequired,
    }));
    
    try {
      if (selectedCamp) {
        await apiService.campaigns.update(selectedCamp._id, {
          title,
          questions,
        });
        toast.success("Campaign updated successfully!");
      } else {
        await apiService.campaigns.create({ questions, title: title.trim() });
        toast.success("Campaign added successfully!");
      }
      fetchData();
      clearForm()
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (selectedList) => {
    const updatedSelection = selectedList.map((q) => ({
      ...q,
      isRequired: false,
    }));
    setSelectedQuestions(updatedSelection);
  };

  const handleRemove = (removedList) => {
    setSelectedQuestions((prev) =>
      prev.filter((q) => !removedList.some((r) => r._id === q._id))
    );
  };

  const handleIsRequiredChange = (questionId) => {
    setSelectedQuestions((prev) =>
      prev.map((q) =>
        q._id === questionId ? { ...q, isRequired: !q.isRequired } : q
      )
    );
  };

  if (!isOpen) return null;

  const disableButton = () => {
    return !title || selectedQuestions.length === 0;
  };

  const filteredOptions = questions.filter((camp) =>
    camp.title.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  return (
    <div className="modal-overlay p-2">
      <div className="modal pt-5 sm:px-8 pb-10 px-4 max-h-[90%] overflow-y-auto relative min-h-[80%] w-full max-w-[700px] mx-auto  border border-border-color">
        <div className="flex justify-end absolute right-5 top-5">
          <button
            onClick={onClose}
            className="rounded-full h-[30px] w-[30px]  text-sm border-2 border-black font-semibold"
          >
            <i className="fa-solid text-black text-lg fa-xmark"></i>
          </button>
        </div>
        <h1 className="text-xl mt-8 font-bold text-center mb-4">
          {selectedCamp ? "Update" : "Add"} Campaign
        </h1>
        <div className="mt-10 flex items-center">
          <label className="font-semibold text-base leading-5 flex-[4]">
            Title
          </label>
          <input
            type="text"
            value={title}
            className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title."
          />
        </div>

        <div className="relative border border-[#C4C4C4] rounded-[5px] px-3 py-4 mt-[34px]">
          <span className="absolute top-2 left-1 font-semibold text-base leading-5 transform translate-y-[-100%] bg-white text-black">
            Select Fields
          </span>

          <div className="flex items-center" ref={dropdownRef}>
            <label className="font-semibold text-base leading-5 flex-[4]">
              Select Questions
            </label>
            <div className="flex-[8] relative">
              <div
                className="bg-white border border-gray-300 rounded cursor-pointer p-2"
                onClick={toggleDropdown}
              >
                Select Questions
              </div>

              {isDropdownOpen && (
                <div className="absolute z-10 bg-white border border-gray-300 w-full mt-1 p-2 max-h-40 overflow-y-auto">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="mb-2 p-1 border border-gray-300 rounded w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {filteredOptions.map((option) => (
                    <label key={option._id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={selectedQuestions.some((q) => q._id === option._id)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      <span className="ml-2">{option.title}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="border mt-5 overflow-x-auto max-h-[250px] overflow-y-auto">
          {selectedQuestions.length > 0 ? (
            <table className="w-full">
              <thead>
                <tr className="border-b border-border-color">
                  <th className="w-[80%] p-3">Question</th>
                  <th className="w-[20%] p-3 text-nowrap">Is Required</th>
                </tr>
              </thead>
              <tbody>
                {selectedQuestions.map((question) => (
                  <tr
                    key={question._id}
                    className="border-b border-border-color"
                  >
                    <td className="p-2 text-sm font-medium">
                      {question.title}
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        className="p-2 accent-black"
                        checked={question.isRequired}
                        onChange={() => handleIsRequiredChange(question._id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center font-medium py-3 capitalize text-[#AC1218]">
              No campaign selected.
            </p>
          )}
        </div>

        <div className="flex justify-center mt-10">
          <button
            onClick={() => handleSubmit()}
            className={`text-sm py-2 px-8 bg-black rounded-2xl mx-auto text-white font-semibold ${disableButton() ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={disableButton()}
          >
            {loading ? <div className="loader" /> : (selectedCamp ? 'Update' : 'Submit')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCampaignModel;
