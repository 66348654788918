import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class LeadsService {
    async getLeads({userType, selectedStatus, page, selectedUser, limit = 10, assigned = false, selectedCamp = ''}) {
        try {
            const response = await axios.get(`/lead?userType=${userType}&page=${page}&status=${selectedStatus}&limit=${limit}&id=${selectedUser}&assigned=${assigned}&campId=${selectedCamp}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;   
        }
    }
    async getById(id, userType = '') {
        try {
            const response = await axios.get(`/lead/${id}?userType=${userType}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;   
        }
    }

    async getAssignedLead(id) {
        try {
            const response = await axios.get(`/lead/getAssignedLead/${id}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;   
        }
    }

    async createLead(payload){
        try {
            const response = await axios.post('/lead', payload);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;   
        }
    }

    async deleteLeads(id) {
        try {
            const response = await axios.delete(`/lead/${id}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;   
        }
    }

    async getLeadById(id, type = '') {
        try {
            const response = await axios.get(`/lead/${id}?userType=${type}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async updateLead(id, payload) {
        try {
            const response = await axios.put(`/lead/${id}`, payload);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }
}

export default new LeadsService();
