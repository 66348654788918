import React, { useState, useEffect } from 'react';
import apiService from '../../apiService';
import { toast } from 'react-toastify';

const initialQuestion = { question: '', type: 'text' }

const AddDataModel = ({ fetchData, isOpen, onClose, selectedQuestion }) => {
  const [inputFields, setInputFields] = useState([initialQuestion]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedQuestion) {
      setInputFields([{ question: selectedQuestion.title, type: selectedQuestion.type }]);
    } else {
      setInputFields([initialQuestion]);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    validateForm();
  }, [inputFields]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleAddField = () => {
    setInputFields([...inputFields, { ...initialQuestion }]);
  };

  const handleChange = (index, key, value) => {
    const fields = [...inputFields];
    fields[index] = { ...fields[index], [key]: value };
    setInputFields(fields);
  };

  const validateForm = () => {
    const hasEmptyFields = inputFields.some(field => !field.question.trim());
    const hasDuplicateTitles = new Set(inputFields.map(field => field.question.trim())).size !== inputFields.length;

    setIsSubmitDisabled(hasEmptyFields || hasDuplicateTitles);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const questions = inputFields.map(field => ({
      title: field.question.trim(),
      type: field.type,
    }));

    try {
      let message = ''
      if (selectedQuestion) {
        await apiService.question.updateQuestion(selectedQuestion._id, { question: questions });
        message = 'Question updated successfully!'
      } else {
        await apiService.question.createQuestion({ questions });
        message = 'Question added successfully!'
      }

      toast.success(message)
      setInputFields([initialQuestion])
      onClose();
      fetchData();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay p-2">
        <div className="modal pt-5 sm:px-8  sm:pb-10 px-4 pb-5 max-h-[90%] overflow-y-auto   w-full max-w-[700px] mx-auto relative border border-border-color">
          <div className=' flex justify-end absolute right-5 top-5'>
            <button onClick={onClose} className='rounded-full h-[30px] w-[30px] text-sm border-2  border-black  font-semibold ml-auto'>
              <i className="fa-solid text-black text-lg fa-xmark"></i>
            </button>
          </div>
          <h1 className='text-xl font-bold text-center mt-8'>
            {selectedQuestion ? 'Edit Question' : 'Add Questions'}
          </h1>
          {!selectedQuestion && <div className='flex justify-end mt-6 -mr-1'>
            <button onClick={handleAddField}><i className="fa-solid fa-plus"></i></button>
          </div>
          }
          {inputFields.map((field, index) => (
            <div key={index} className="w-full mb-[10px] mt-5 flex gap-2 justify-between">
              <input
                type="text"
                value={field.question}
                className='outline-none w-full border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm'
                onChange={(e) => handleChange(index, 'question', e.target.value)}
                placeholder='Add your question title.'
              />
              <select
                value={field.type}
                className='border border-[#C4C4C4] bg-[#F9F9F9] rounded-md text-sm p-2 font-semibold outline-none'
                onChange={(e) => handleChange(index, 'type', e.target.value)}
                disabled={selectedQuestion?.isFixed}
              >
                <option value="text" className='font-medium'>Text</option>
                <option value="number" className='font-medium'>Number</option>
                <option value="email" className='font-medium'>Email</option>
                <option value="date" className='font-medium'>Date</option>
                <option value="radio" className='font-medium'>Yes/No</option>
              </select>
            </div>
          ))}

          <div className='flex justify-center mt-10'>
            <button
              onClick={handleSubmit}
              className={`text-sm py-2 px-6 bg-black rounded-2xl mx-auto text-white font-semibold ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isSubmitDisabled}
            >
              {loading ? <div className="loader" /> : (selectedQuestion ? 'Update' : 'Submit')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDataModel;
