export const roleList = [
    { value: 'all', label: 'All' },
    { value: 'vendor', label: 'Vendor' },
    { value: 'staff', label: 'Staff' },
];

export const options = [
    { value: '', label: 'All' },
    { value: 'submitted', label: 'Submitted' },
    { value: 'pending', label: 'Pending' },
    { value: 'approve', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
];

export const roleOptions = [
    { title: "Verifier", value: "verifier" },
    { title: "Lead management", value: "lead_management" },
];

export const allStatus = [
    { label: "Answering Machine", value: 'answering_machine' },
    { label: "Callback", value: 'callback' },
    { label: "Verified", value: 'verified' },
    { label: "Vm", value: 'vm' },
    { label: "New", value: 'new' },
    { label: "Under Verification", value: 'under_verification' },
    { label: "Submitted To Attorney", value: 'submitted_to_attorney' },
    { label: "Approve", value: 'approve' },
    { label: "Reject", value: 'reject' },
    { label: "Return", value: 'return' },
    { label: "Replace", value: 'replace' },
    { label: "Billable", value: 'billable' },
    { label: "Paid", value: 'paid' },
]
export const adminStatusList = [
    { label: "Approve", value: 'approve' },
    { label: "Billable", value: 'billable' },
    { label: "Paid", value: 'paid' },
    { label: "Reject", value: 'reject' },
    { label: "Return", value: 'return' },
    { label: "Replace", value: 'replace' },
]

export const statusList = [
    { label: "Answering Machine", value: 'answering_machine' },
    { label: "Callback", value: 'callback' },
    { label: "Verified", value: 'verified' },
    { label: "Vm", value: 'vm' }
]

export const onlyStaffStatus = ['answering_machine', 'callback', 'vm']
export const onlyAdminStatus = ['approve', 'reject', 'return', 'replace', 'billable', 'paid']
export const beforeSubmitToAtoFAdmin = [{ label: "Verified", value: 'verified' }]
export const afterSubmitToAto = ["approve", "reject", "return", "replace", "billable", "paid"]
