import { toast } from 'react-toastify';
import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class QuestionService {
    async getQuestions(search = '', page = 0, limit = 10) {
        try {
            const response = await axios.get(`/question?search=${search}&page=${page}&limit=${limit}`);
            return response.data;
        } catch (error) {
            throw Error(error);

        }
    }

    async getAllQuestionById() {
        try {
            const response = await axios.get(`/question/getAllQuestionById`);
            return response.data;
        } catch (error) {
            throw Error(error);

        }
    }

    async getQuestionsByCampId(campId) {
        try {
            const response = await axios.get(`/question/getAllQuestionByCampId/${campId}`);
            return response.data;
        } catch (error) {
            throw Error(error);

        }
    }

    async createQuestion(body) {
        try {
            const response = await axios.post('/question', body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }

    async updateQuestion(id, body) {
        try {
            const response = await axios.put(`/question/${id}`, body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);

        }
    }

    async deleteQuestion(id) {
        try {
            const response = await axios.delete(`/question/${id}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            throw Error(error);
        }
    }
}

export default new QuestionService();
