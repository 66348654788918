import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { redirectToRoleBasedRoute } from "../utils/auth";
import ToastNotification from "../components/shared/ToastNotification";

const UnauthenticatedLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      redirectToRoleBasedRoute(token, navigate);
    }
  }, [navigate]);

  return (
    <main className="min-h-[100vh] flex items-center justify-center">
      <ToastNotification />

      <Outlet />
    </main>
  );
};

export default UnauthenticatedLayout;
