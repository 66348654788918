import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class UserService {
    async getClients() {
        try {
            const response = await axios.get('/user/getClients');
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;
        }
    }

    async getClientConfiguration(body) {
        try {
            const response = await axios.post('/user/getClientConfiguration', body);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;
        }
    }
}

export default new UserService();
