import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserRoleFromToken } from "../utils/auth";
import apiService from "../apiService";

const PrivateRoute = ({ element, allowedRoles }) => {
  const [isTokenValid, setIsTokenValid] = useState(null);
  const token = localStorage.getItem("accessToken");
  const userRole = getUserRoleFromToken();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await apiService.auth.verifyToken()
        if (response.success) {
          setIsTokenValid(response.data.isValid);
        } else {
          localStorage.removeItem('accessToken')
        }
      } catch (error) {
        setIsTokenValid(false);
        localStorage.removeItem('accessToken')
      }
    };

    if (token) {
      verifyToken();
    } else {
      setIsTokenValid(false);
    }
  }, [token]);

  if (isTokenValid === null) {
    return <></>;
  }

  if (!isTokenValid) {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles.includes(userRole)) {
    return element;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

export default PrivateRoute;
