import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import ToastNotification from "../components/shared/ToastNotification";

const AuthenticatedLayout = () => {
  return (
    <>
      <ToastNotification />
      <Header />
      <main className="min-h-[calc(100vh-120px)]">
        <div className="min-h-screen flex">
          <Sidebar />
          <main
            style={{ minHeight: '90svh' }}
            className="lg:ml-64 w-full p-5 bg-[#F9F9F9] overflow-y-auto">
            <Outlet />
          </main>
        </div>
      </main>
    </>
  );
};

export default AuthenticatedLayout;
