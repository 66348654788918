import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { roleOptions } from "../../utils/constant";

const StaffModel = ({ isOpen, onClose, fetchData, staff }) => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: !staff ? Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[0-9]/, "Password must contain a number")
      .matches(/[!@#$%^&*]/, "Password must contain a special character")
      .required("Password is required") : Yup.string(),
    selectedRoles: Yup.array().min(1, "At least one role must be selected"),
    selectedCamps: Yup.array().min(1, "At least one campaign must be selected"),
  });

  const initialValues = {
    username: staff ? staff.name : "",
    email: staff ? staff.email : "",
    password: "",
    selectedRoles: staff ? staff.role.map((role) => roleOptions.find((data) => data.value === role)) : [],
    selectedCamps: campaigns?.filter((camp) => staff?.campIds?.includes(camp?.value))
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      fetchCamps();
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const fetchCamps = async () => {
    try {
      const result = await apiService.campaigns.get("", 0, -1);
      const options = result.data.campaigns.map((camps) => ({
        label: camps.title,
        value: camps._id,
      }));
      setCampaigns(options);
    } catch (error) {
      console.error("Error fetching campaigns", error);
      toast.error("Failed to fetch campaigns.");
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const userData = {
      name: values.username,
      email: values.email,
      password: values.password,
      role: values.selectedRoles.map((role) => role.value),
      userType: "staff",
      campIds: values.selectedCamps.map((camp) => camp.value),
    };

    try {
      let response;
      if (staff) {
        response = await apiService.auth.updateUser(staff._id, userData);
      } else {
        response = await apiService.auth.register(userData);
      }

      if (response?.success) {
        fetchData();
        toast.success(`Staff ${staff ? "updated" : "created"} successfully!`);
        onClose();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(`Error ${staff ? "updating" : "creating"} user:`, error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal pt-5 sm:px-8 sm:pb-10 max-h-[90%] overflow-y-auto px-4 pb-5 relative max-w-[700px] mx-auto w-full">
          <div className="flex justify-end absolute top-5 right-5">
            <button
              type="button"
              className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
              onClick={onClose}
            >
              <i className="fa-solid text-black text-lg fa-xmark"></i>
            </button>
          </div>
          <h2 className="text-center font-bold text-xl leading-6 mt-8">
            {staff ? "Edit Staff" : "Add Staff"}
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting, values, isValid, setFieldValue }) => (
              <Form className="mt-10">
                <div className="flex">
                  <label className="font-semibold text-base leading-5 flex-[4]">Username</label>
                  <div className="flex-[8]">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Username"
                      className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                    />
                    <ErrorMessage name="username" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
                <div className="mt-3 flex">
                  <label className="font-semibold text-base leading-5 flex-[4]">Email</label>
                  <div className="flex-[8]">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
                <div className="mt-3 flex">
                  <label className="font-semibold text-base leading-5 flex-[4]">Password</label>
                  <div className="flex-[8] relative">
                    <Field
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                    />
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                    <i
                      className={`fa-solid ${passwordVisible ? "fa-eye" : "fa-eye-slash"} absolute right-4 top-3 cursor-pointer`}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    ></i>
                  </div>
                </div>
                <div className="relative border border-[#C4C4C4] rounded-[5px] px-3 py-4 mt-[34px]">
                  <span className="absolute top-2 left-1 font-semibold text-base leading-5 transform -translate-y-[100%] bg-white text-black">
                    Select Fields
                  </span>
                  <div className="flex items-center">
                    <label className="font-semibold text-base leading-5 flex-[4]">Select Role</label>
                    <div className="flex-[8]">
                      <Multiselect
                        options={roleOptions}
                        selectedValues={values.selectedRoles}
                        displayValue="title"
                        onSelect={(selectedList) => setFieldValue("selectedRoles", selectedList)}
                        onRemove={(selectedList) => setFieldValue("selectedRoles", selectedList)}
                        placeholder="Select role"
                        style={{ chips: { background: "#F6F6F6", color: "black", fontWeight: "600" } }}
                      />
                    </div>
                  </div>
                  <div className="mt-3 flex items-center">
                    <label className="font-semibold text-base leading-5 flex-[4]">Select Campaigns</label>
                    <div className="flex-[8]">
                      <Multiselect
                        options={campaigns}
                        selectedValues={values.selectedCamps}
                        displayValue="label"
                        onSelect={(selectedList) => setFieldValue("selectedCamps", selectedList)}
                        onRemove={(selectedList) => setFieldValue("selectedCamps", selectedList)}
                        placeholder="Select campaigns"
                        style={{ chips: { background: "#F6F6F6", color: "black", fontWeight: "600" } }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10 flex justify-center gap-2">
                  <button
                    type="submit"
                    className={`py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold ${(!isValid || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    disabled={!isValid || isSubmitting || !values.selectedRoles.length || !values.selectedCamps.length}
                  >
                    {loading ? <div className="loader" /> : staff ? "Update" : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  );
};

export default StaffModel;
