import React, { useEffect, useState, useCallback } from "react";
import ApiService from '../../apiService';
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import { toast } from 'react-toastify';
import StaffModel from "../model/StaffModel";
import { roleOptions } from "../../utils/constant";
import Pagination from "../shared/Pagination";

const StaffTable = ({ title, showButton }) => {
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [staff, setStaff] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [staffToDelete, setStaffToDelete] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    // Debounce search input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    const fetchData = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            const result = await ApiService.auth.getUser('staff', debouncedSearchQuery, page, '', rowsPerPage);
            if (result?.success) {
                const { data } = result;
                setStaff(data.users || []);
                setTotalPages(data.totalPages || 1);
                setCurrentPage(data.currentPage || 1);
                setTotalData(data.totalUsers || 1);
            }
        } catch (error) {
            toast.error('Failed to fetch staff.');
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchQuery, rowsPerPage]);

    useEffect(() => {
        fetchData(currentPage);
    }, [fetchData, currentPage, rowsPerPage]);

    const handleEditStaff = (staff) => {
        setSelectedStaff(staff);
        setIsModalOpen(true);
    };

    const handleAddStaff = () => {
        setSelectedStaff(null);
        setIsModalOpen(true);
    };

    const openDeleteConfirmation = (staffId) => {
        setStaffToDelete(staffId);
        setIsDeleteConfirmationOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(false);
        setStaffToDelete(null);
        document.body.style.overflow = 'unset';
    };

    const handleDelete = async () => {
        if (!staffToDelete) return;

        setIsDeleting(true); // Start loading
        try {
            await ApiService.auth.delete(staffToDelete);
            fetchData();
            toast.success('Staff deleted successfully!');
        } catch (error) {
            console.error('Error deleting staff:', error);
            toast.error('Failed to delete staff.');
        } finally {
            setIsDeleting(false); // Stop loading
            closeDeleteConfirmation();
        }
    };

    const renderRole = (role = '') => {
        let option = roleOptions.find((r) => r.value === role);
        return option?.title || role;
    };

    return (
        <>
            <div className="flex flex-col space-y-3">
                <div className="flex justify-end">
                    <div className="w-full md:w-4/12">
                        <div className="overflow-hidden border border-gray-300 shadow-d rounded-[20px]">
                            <div className="relative flex items-end justify-end py-2 bg-white">
                                <div className="relative flex items-center w-full">
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        className="w-full py-1 pl-12 pr-4 text-sm font-medium text-gray-700 rounded-[20px] focus:outline-none"
                                        placeholder="Search Staff"
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <div className="absolute inset-y-0 flex items-center pointer-events-none left-5">
                                        <i className="text-lg text-gray-500 fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2 border bg-white border-border-dark rounded-[20px] overflow-hidden">
                <div className="flex items-center py-1 px-4 justify-center relative">
                    <h1 className="font-bold p-2 text-center leading-8 text-2xl">{title}</h1>
                    <button className="absolute right-4 top-3" onClick={handleAddStaff}>
                        <img src={"/assets/svg/plusbutton.svg"} alt="Add" />
                    </button>
                </div>
                <div className="w-full overflow-x-auto border-t border-border-dark min-h-[300px]">
                    {loading && <SkeletonLoader />}
                    {!loading && (
                        <table className="w-full border-collapse scrolldown">
                            <thead>
                                <tr className="bg-[#F6F6F6] h-[41.9px]">
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[5%]">S.No.</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[20%]">Username</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[30%]">Email</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[25%]">Role</th>
                                    <th className="text-base font-bold px-4 w-[10%]">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white w-full">
                                {staff.map((member, index) => (
                                    <tr key={member._id} className="border-b border-border-dark">
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                                            {(currentPage - 1) * rowsPerPage + index + 1}
                                        </td>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">{member.name}</td>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">{member.email}</td>
                                        <td className="text-sm font-bold px-2 py-3 border-r border-border-dark">
                                            <div className="flex gap-1">
                                                {member.role.map((r) => (
                                                    <div className="rounded-md bg-slate-800 py-0.5 px-2.5 border border-transparent text-sm text-white transition-all shadow-sm w-fit">
                                                        {renderRole(r)}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                        <td className="text-base font-bold px-2 py-3 flex justify-center items-center gap-1">
                                            <button className="text-sm font-bold text-black" onClick={() => handleEditStaff(member)}>Edit</button>
                                            |
                                            <button className="text-sm font-bold text-[#C30000]" onClick={() => openDeleteConfirmation(member._id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    rowsPerPage={rowsPerPage}
                    onPageChange={setCurrentPage}
                    loading={loading}
                    totalData={totalData}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value);
                        setCurrentPage(1);
                    }}
                />
                <StaffModel
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setSelectedStaff(null);
                    }}
                    fetchData={fetchData}
                    staff={selectedStaff}
                />

                {isDeleteConfirmationOpen && (
                    <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white rounded-lg p-4">
                            <p className="text-lg font-semibold py-10">Are you sure you want to delete this staff?</p>
                            <div className="flex justify-end mt-4 font-semibold">
                                <button
                                    className={`bg-[#AC1218] text-white ${isDeleting ? "px-5" : "px-2"} py-1  text-sm font-semibold rounded-md`}
                                    onClick={handleDelete}
                                    disabled={isDeleting}
                                >
                                    {isDeleting ? <div className="loader" /> : "Delete"}
                                </button>
                                <button
                                    className="ml-4 border border-border-color py-1 px-2 text-sm rounded-md"
                                    onClick={closeDeleteConfirmation}
                                    disabled={isDeleting}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default StaffTable;
