import AuthService from './authService';
import QuestionService from './questionService';
import LeadsService from './leads';
import CampaignsService from './campaignService';
import UserService from './userService';
import ClientService from './clientService';

const apiService = {
    auth: AuthService,
    question: QuestionService,
    leads: LeadsService,
    campaigns: CampaignsService,
    user: UserService,
    client: ClientService,
};

export default apiService;
