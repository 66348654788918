import { toast } from "react-toastify";

export const handleErrorToaster = (error) => {
    const errors = error?.response?.data?.errors;
    const message = error?.response?.data?.message;

    if (Array.isArray(errors) && errors.length > 0) {
        errors.forEach((err) => {
            toast.error(err?.msg || err?.message);
        });
    } else if (errors?.data && errors?.message) {
        const missingFields = errors.data.missingFields?.join(', ') || '';
        toast.error(`${errors.message} ${missingFields}`);
    } else if (message) {
        toast.error(message);
    } else {
        toast.error('Something went wrong!');
    }
};

export const handleDownload = async (url, filename) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
    } catch (error) {
        console.error("Error downloading the file", error);
    }
};

export const formatDate = (date) => new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
});

export const trimText = (text = '', count = 30) => {
    return text.length > count ? `${text.slice(0, count)}...` : text
}

export const getLeadData = (resp, type) => {
    if (type === 'name') {
        const f_name = resp.find((d)=> d.question === 'first_name').answer
        const l_name = resp.find((d)=> d.question === 'last_name').answer

        return f_name + " " + l_name || undefined
    }

    const data = resp.find((d)=> d.question === type).answer
    return data
}