import React, { useCallback, useEffect, useState } from 'react';
import apiService from '../../apiService';
import { useParams } from 'react-router-dom';
// import LeadModel from '../model/LeadModel';
import Skeleton from '../skeletonloader/Request';
import ReactDropdown from 'react-dropdown';
import { toast } from 'react-toastify';
import { afterSubmitToAto, allStatus, statusList } from '../../utils/constant';
// import { isStaffVerifier } from '../../utils/auth'

const LeadView = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalAction, setModalAction] = useState(null);
  const { id: leadId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await apiService.leads.getAssignedLead(leadId);
      if (result?.success) {
        setData(result.data);
      }
    } catch (error) {
      console.error('Failed to fetch lead data:', error);
    } finally {
      setLoading(false);
    }
  }, [leadId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const handleActionClick = (action) => {
  //   setModalAction(action);
  //   setIsModalOpen(true);
  // };

  const onStatusChange = async (status) => {
    try {
      const data = await apiService.leads.updateLead(leadId, { status });
      if (data.success) {
        toast.success(data?.message || '')
        fetchData()
      }
    } catch (error) {
      console.error("Failed to update lead status:", error);
    }
  }

  return (
    <div className="mt-4 border bg-white border-border-dark rounded-[20px] overflow-hidden">
      {loading ? (
        <Skeleton count={4} />
      ) : (
        <>
          <div className='flex justify-between gap-2 p-3 items-center'>
            <h1 className=' pt-3 text-lg'>Created By - <b className=''>{data?.createdBy?.name}</b></h1>

            {afterSubmitToAto.includes(data.status) ?
              <b style={{ textTransform: "capitalize" }}>
                {statusList.find((d) => d.value === data?.status)?.label}
              </b> : <ReactDropdown
                options={statusList}
                onChange={(e) => {
                  setSelectedStatus(e.value)
                  onStatusChange(e.value)
                }}
                value={allStatus.find((s)=> s?.value === (data?.status || selectedStatus))?.label}
                placeholder="Status"
                className="font-medium  shrink-0 min-w-dropdown"
              />}

            {/* {(data.status !== 'rejected' && data.status !== 'approved' && isStaffVerifier()) ? <div className='flex gap-2'>
              <button className='px-4 py-1 bg-black text-white font-semibold rounded-lg' onClick={() => handleActionClick('accept')}>
                Approve
              </button>
              <button className='px-4 py-1 bg-black text-white font-semibold rounded-lg' onClick={() => handleActionClick('reject')}>
                Reject
              </button>
            </div> : <b className='' style={{textTransform: "capitalize"}}>{data.status}</b>} */}
          </div>
          <p className='text-center text-2xl mt-2 pb-3 border-b border-border-color'><b>Answers</b></p>

          <div className='rounded-md mt-2 overflow-x-auto'>
            {data?.responses?.map((resp, index) => (
              <div key={index} className='mt-1 border-b pb-3 px-3 border-border-color flex items-center justify-between gap-3'>
                <h1><b>{index + 1} {resp?.questionTitle}</b></h1>
                <p>{resp?.response}</p>
              </div>
            ))}
          </div>

          {/* <LeadModel
            isOpen={isModalOpen}
            actionType={modalAction}
            onClose={() => setIsModalOpen(false)}
            onSubmit={fetchData}
            leadId={leadId}
          /> */}
        </>
      )}
    </div>
  );
};

export default LeadView;
