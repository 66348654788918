import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="border bg-white border-border-dark overflow-hidden w-full">
      <div className="w-full overflow-x-auto border-t border-border-dark">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-[#F6F6F6] h-[41.9px]">
              {Array(7).fill(null).map((_, index) => (
                <th key={index} className="text-base font-bold px-4 border-r border-border-dark w-[15%]">
                  <div className="h-4 bg-gray-300 rounded animate-pulse" />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white w-full">
            {Array(5).fill(null).map((_, index) => (
              <tr key={index} className="border-b border-border-dark">
                {Array(7).fill(null).map((_, cellIndex) => (
                  <td key={cellIndex} className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                  <div className="h-4 bg-gray-300 rounded animate-pulse" />
                </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SkeletonLoader;
