import React from 'react'
import RequestTable from '../../components/table/Request'
import { getUserRoleFromToken } from '../../utils/auth'

const Request = () => {
  const userType = getUserRoleFromToken()
  return (
    <RequestTable title={'Requests'} userType={userType} />
  )
}

export default Request
